import React from 'react';
import BSATemplate from './bsa-template.component';

const BroadlyStudiedAntiemetic = () => {
  return (
    <>
      <BSATemplate
        header="Prophylaxis for PONV"
        copy='The efficacy of <span>Barhemsys 5 mg</span> for the prevention of PONV was evaluated in 2 randomized, double-blind, placebo-controlled, multicenter trials in patients undergoing general anesthesia and elective surgery. In both trials, patients were administered Barhemsys at the induction of anesthesia.<sup>6</sup>'
        dosageData={[
          {
            dosage: '5 mg',
            header: 'Monotherapy Prophylaxis',
            html: 'Study 1 (N=342) patients received monotherapy with Barhemsys<sup>6,7</sup>',
          },
          {
            dosage: '5 mg',
            header: 'Combination Prophylaxis',
            html: 'Study 2 (N=1147) patients received Barhemsys in combination with 1 other intravenously administered, nondopaminergic antiemetic<sup>6,8</sup>',
          },
        ]}
      />
      <BSATemplate
        header="Treatment for PONV"
        copy='The efficacy of <span>Barhemsys 10 mg</span> as a single dose was evaluated in 2 randomized, double-blind, placebo-controlled, multicenter trials in patients experiencing PONV after general anesthesia and elective surgery.<sup>6</sup>'
        dosageData={[
          {
            dosage: '10 mg',
            header: 'Treatment',
            html: 'Study 3 (N=369) enrolled patients who had not received PONV prophylaxis<sup>6,9</sup>',
          },
          {
            dosage: '10 mg',
            header: 'Rescue Treatment',
            html: 'Study 4 (N=465) enrolled patients with moderate to high risk of PONV after they failed antiemetic prophylaxis with an antiemetic of another class<sup>4,6</sup>',
          },
        ]}
        useAlt={true}
      />
    </>
  );
}

export default BroadlyStudiedAntiemetic;
