import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-footer.module.scss';

const PageFooterModule = ({text}) => {
  return (
    <div className={styles.pageFooterModule}>
      <RenderMarkdown
        text={text}
      />
    </div>
  );

}

export default PageFooterModule;
