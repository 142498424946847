import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { strToClassName } from '~utils/strToClassName';
import ContentModules from '~components/content-modules/content-modules.component';

import styles from './q-and-a.module.scss';

class QAndA extends Component {
  state = {
    isAnswered: false,
  };

  renderInputs = () => {
    const { answers } = this.props;
    return answers.map((v, i) => {
      const parsedVal = `${strToClassName(v)}__${uuid()}`;
      return (
        <div key={i} className={styles.ponvIncidenceQa__inputGroup}>
          <label
            htmlFor={parsedVal}
            className={styles.ponvIncidenceQa__radioContainer}
          >
            {v}
            <input
              type="radio"
              className={styles.ponvIncidenceQa__radio}
              id={parsedVal}
              name="q-a"
              value={parsedVal}
              aria-label={v}
            />
            <span className={styles.ponvIncidenceQa__radioBtn}></span>
          </label>
        </div>
      );
    });
  }

  renderAnswer = () => {
    if (!this.state.isAnswered) {
      return null;
    }

    const { contentModules } = this.props;

    return (
      <div className={styles.ponvIncidenceQa__answer}>
        <ContentModules
          contentModules={contentModules}
        />
      </div>
    );
  }

  onChange = () => {
    this.setState({
      isAnswered: true,
    })
  }

  render() {
    const { renderInputs, renderAnswer, onChange } = this;
    const { question } = this.props;

    return (
      <div className={styles.ponvIncidenceQa}>
        <form
          className={styles.ponvIncidenceQa__form}
          onChange={onChange}
        >
          <p className={styles.ponvIncidenceQa__question}>
            {question}
          </p>
          {renderInputs()}
        </form>
        {renderAnswer()}
      </div>
    );
  }
}

export default QAndA;
