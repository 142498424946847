import React, { Component, createRef } from 'react';
import { strToClassName } from '~utils/strToClassName';
import { connect } from 'react-redux';

import styles from './content-tabs.module.scss';

const breakpoint = 992;

class ContentTabs extends Component {
  static defaultProps = {
    tabsHeightOffset: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      anchors: [],
      mobileHasScrolled: false,
    }
    this.refContentTabs = createRef();
  }

  componentDidMount() {
    this.initAnchors();
    this.handleUrlHashScroll();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { triggleScroll } = this.props;
    const { mobileHasScrolled } = this.state;
    const isMobileView = window.innerWidth < breakpoint;

    if (isMobileView && !mobileHasScrolled) {
      this.handleUrlHashScroll();
      this.setState({
        mobileHasScrolled: true,
      });
    }

    if (!isMobileView && triggleScroll) {
      this.handleUrlHashScroll();
    }
  }

  initAnchors = () => {
    const { anchors } = this.props;
    const updatedAnchors = anchors.map((v, i) => {
      return {
        title: v.title,
        anchor: `#page-anchor__${strToClassName(v.title)}`,
      }
    });

    this.setState({
      anchors: updatedAnchors,
    })
  }

  onClickedAnchor = (e) => {
    e.preventDefault();
    this.scrollToAnchor(e.target.hash);
    const active = document.querySelectorAll(`.${styles.contentTabs__colActive}`);
    const allTabs = document.querySelectorAll("[href='" + window.location.pathname + e.target.hash + "']");
    active.forEach(function(node, idx) {
      node && node.classList.remove(styles.contentTabs__colActive);
    });
    allTabs.forEach(function(node, idx) {
      node.classList.add(styles.contentTabs__colActive);
    });
  }

  handleUrlHashScroll = () => {
    // Abort if there is no hash in the URL. This is intended for direct linking.
    if (!window.location.hash) {
      return;
    }
    this.scrollToAnchor(window.location.hash);
  }

  scrollToAnchor = hash => {
    const element = document.getElementById(hash.substring(1));
    const isMobileView = window.innerWidth < breakpoint;

    if (element) {
      const { headerHeight, tabsHeightOffset } = this.props;
      const rect = element.getBoundingClientRect();
      const win = element.ownerDocument.defaultView;
      const offsetTop = isMobileView
        ? rect.top + win.pageYOffset - 10
        : rect.top + win.pageYOffset - (headerHeight + tabsHeightOffset + 10);

      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  }

  renderTabs = () => {
    const { onClickedAnchor } = this;
    const { anchors } = this.state;
    return anchors.map((v, i) => {
      return (
        <a
          key={i}
          className={styles.contentTabs__col}
          href={`${window.location.pathname}${v.anchor}`}
          onClick={onClickedAnchor}
        >
          {v.title}
        </a>
      );
    })
  }

  render() {
    const { refContentTabs, renderTabs } = this;
    return (
      <div
        ref={refContentTabs}
        className={styles.contentTabs}
      >
        {renderTabs()}
      </div>
    );
  }
}

const mapStateToProps = ({header: {headerHeight}}) => {
  return {
    headerHeight,
  }
};

export default connect(mapStateToProps)(ContentTabs);
