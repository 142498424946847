import React, { useEffect } from 'react';
import RenderMarkdown from '~components/RenderMarkdown';
import styles from './leadgenform.module.scss';

const LeadGenForm = ({title, text, plgfaccount, plgfid, plgfdomain}) => {
  useEffect(() => {
    const renderForm = () => {
      const settings = `var ss_form = {'account': '`+plgfaccount+`', 'formID': '`+plgfid+`'};
        ss_form.width = '100%';
        ss_form.domain = 'app-`+plgfdomain+`';
        ss_form.hidden = {'_usePlaceholders': true};
        ss_form.target_id = 'leadgen-form';
        ss_form.polling = true;`;
      createScript(settings, "script");
      createScript(`https://koi-`+plgfdomain+`/client/form.js?ver=2.0.1`, "src");
      // this.createScript(settings, "script");
      // this.createScript("https://koi-3QNLPQABDS.marketingautomation.services/client/form.js?ver=2.0.1", "src");
    }
  
    const createScript = (data, type) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      type === "src" ? script.src = data : script.innerHTML = data;
      document.body.appendChild(script);
      // console.log(script);
    }
    setTimeout(renderForm, 1500);
  },[plgfaccount, plgfid, plgfdomain])
  
  return (
    <div className={styles.pageLeadGenForm}>
      <RenderMarkdown 
        text={text}
        paragraph="p" 
      />
      <RenderMarkdown 
        text={title}
        paragraph="h2" 
      />
      <div id="leadgen-form" />
    </div>
  );
}

export default LeadGenForm;