import React from 'react';
import {Link} from "gatsby";

import styles from './page-footer-links.module.scss';

const PageFooterLinks = ({ links }) => {
  if (!links || !links.length) {
    return null;
  }

  return (
    <div className={styles.pageFooterLinks}>
      <ul className={styles.list}>
        {
          links.map((link, i) => {
            return (
              <li
                key={i}
                className={styles.item}
              >
                <div className={styles.col}>
                  {link.title}
                </div>
                <div className={styles.col}>
                  <Link
                    to={`/${link.url}`}
                    className={styles.btn}
                  >
                    Learn more
                  </Link>
                </div>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
}

export default PageFooterLinks;
