import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './tab.component';

import styles from './tabs.module.scss';

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <div className={styles.tabs}>
        <div className={styles.tabsList}>
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                style={{width: `${100 / children.length}%`}}
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </div>
        <div className={styles.tabsContent}>
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
