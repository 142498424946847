import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './antiemetic-guidelines.module.scss';

const GuidelinesCard = ({ number, title, body, columns }) => (
  <div className={styles.card}>
    <div className={styles.number}>{number}</div>
    <h2 className={styles.cardTitle}>{title}</h2>
    {body &&
      <div className={styles.cardBody}>
        <RenderMarkdown text={body.body} />
      </div>
    }
    <div className={styles.wrapper}>
      {columns && columns.map((column, i) => (
        <div key={i} className={styles.column}>
          {column.icon &&
            <div className={styles.columnIcon}>
              <img src={column.icon.file.url} alt={column.icon.description} />
            </div>
          }
          <div className={styles.columnBody}>
            <RenderMarkdown text={column.body.body} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

const AntiemeticGuidelines = ({ title, steps }) => {
  return (
    <div className={styles.antiemeticGuidelines}>
      <h2 className={styles.title}>
        <RenderMarkdown text={title} />
      </h2>
      {steps.map((step, i) => (
        <GuidelinesCard
          key={i}
          number={i + 1}
          title={step.title}
          body={step.body}
          columns={step.columns}
        />
      ))}
    </div>
  )
};

export default AntiemeticGuidelines;
