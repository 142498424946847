import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-table.module.scss';

const PageTable = ({ body, color, headers, values, references, wrapperClass }) => {
  let wrapperClasses = [
    styles.wrapper,
  ];

  let tableClasses = [
    styles.table,
    color,
  ];

  if (wrapperClass) {
    wrapperClasses.push(wrapperClass);
  }

  if (wrapperClass) {
    tableClasses.push(wrapperClass);
  }

  return (
    <div className={wrapperClasses.join(' ')}>
      {body && <RenderMarkdown text={body.tableBody} />}
      <div className={tableClasses.join(' ')}>
        {headers.map((header, i) => {
          let headerStr = header.tableHeader;
          if (header.tableHeader) {
            // Parse the Markdown new line "  \n" from Contentful.
            headerStr = header.tableHeader.replace(/\s\s\\n/g, '  \n');
          }
          return (
            <div key={i} className={styles.tableHeader}>
              <RenderMarkdown
                text={headerStr}
                paragraph="span"
              />
            </div>
          )
        })}
        {values.map((value, i) => {
          let valueStr = value.tableValue;
          if (value.tableValue) {
            // Parse the Markdown new line "  \n" from Contentful.
            valueStr = value.tableValue.replace(/\s\s\\n/g, '  \n');
          }
          return (
            <div key={i} className={styles.tableBody}>
              <RenderMarkdown
                text={valueStr}
                paragraph="span"
              />
            </div>
          )
        })}
      </div>
      {references &&
      <div className={styles.tableReferences}>
        <RenderMarkdown text={references.tableReferences} />
      </div>
      }
    </div>
  );
}

export default PageTable;
