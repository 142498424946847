import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer'
import Container from '~components/container/container.component';
import PageWrapper from '~components/page-wrapper/page-wrapper.component';
import SEO from '~components/seo';
import Hero from '~components/hero/hero.component';
import Cta from '~components/cta/cta.component';
import ContentTabs from '~components/content-tabs/content-tabs.component';
import ContentModules from '~components/content-modules/content-modules.component';
import PageFooterLinks from '~components/page-footer-links/page-footer-links.component';

import '~styles/pages/_content-page.scss';

const Page = ({ data }) => {
  const refFixedPageTabs = useRef(null);
  const refFixedPageTabsWrapper = useRef(null);
  const [fixedPageTabsHeight, setFixedPageTabsHeight] = useState(0);
  const [fixedPageTabsPos, setFixedPageTabsPos] = useState(0);
  const RESET_TIMEOUT = 100;

  useEffect(() => {
    // Holds the timer for setTimeout and clearInterval.
    let timer = null;

    const updateDimsPos = () => {
      if (refFixedPageTabsWrapper.current) {
        setTimeout(() => {
          setFixedPageTabsHeight(refFixedPageTabsWrapper.current.clientHeight);
        }, 100)
      }
      if (refFixedPageTabs.current) {
        setTimeout(() => {
          setFixedPageTabsPos(refFixedPageTabs.current.offsetTop - 2 + fixedPageTabsHeight);
        }, 200)
      }

    }

    window.addEventListener('resize', () => {
      clearInterval(timer);
      timer = setTimeout(updateDimsPos, RESET_TIMEOUT);
    });
    updateDimsPos();
  }, [
    refFixedPageTabsWrapper,
    setFixedPageTabsHeight,
    refFixedPageTabs,
    fixedPageTabsHeight,
    setFixedPageTabsPos,
  ]);

  const {
    title,
    description,
    contentModules,
    hero,
    footerLinks,
    ctaSidebar,
    references
  } = data.contentfulPage;

  const pageTabs = contentModules.filter(item => {
    return item.__typename === 'ContentfulPageAnchor';
  });

  const renderSeo = () => {
    if (!description) {
      return <SEO title={title} />;
    }
    return (
      <SEO
        title={title}
        description={description.description}
      />
    );
  }

  const renderHero = () => {
    if (!hero) {
      return null;
    }
    return (
      <div className="pageContentHead" id={title}>
        <h1 className="page-title-top">{title}</h1>
        <Hero data={hero} isExternal={false} />
      </div>
      )
  }

  const [ref, inView, entry] = useInView({
    rootMargin: `-${fixedPageTabsPos}px 0px 0px 0px`,
  });

  const renderPageTabs = () => {
    if (!pageTabs || !pageTabs.length) {
      return null;
    }

    return (
      <div ref={ref} style={{marginBottom: '25px'}}>
        <ContentTabs
          anchors={pageTabs}
          tabsHeightOffset={fixedPageTabsHeight}
        />
      </div>
    );
  }

  const renderFixedPageTabs = () => {
    let isShown = pageTabs && pageTabs.length && entry && !inView;
    const colClass = ctaSidebar ? 'column-2' : 'column-1';
    let tabsWarpperClasses = [
      'content__tabs-wrapper',
    ];

    if (!isShown) {
      tabsWarpperClasses.push('content__tabs-wrapper--hidden');
    }

    return (
      <div ref={refFixedPageTabs}>
        <Container>
          <div className={colClass}>
            <div className="content content__tabs">
              <div ref={refFixedPageTabsWrapper} className={tabsWarpperClasses.join(' ')}>
                <ContentTabs
                  anchors={pageTabs}
                  tabsHeightOffset={fixedPageTabsHeight}
                  triggleScroll={isShown}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  const columnOne = () => (
    <div className="column-1">
      <div className="content">
        {renderPageTabs()}
        <ContentModules contentModules={contentModules} />
        <PageFooterLinks links={footerLinks} />
      </div>
    </div>
  );

  const columnTwo = () =>(
    <div className="column-1">
      
      <div className="content">
        {renderPageTabs()}
        <div className="sidebar">
        {ctaSidebar.map((cta, i) => (
          <Cta
            key={i}
            {...cta}
            body={cta.childContentfulCtaBlockBodyTextNode}
          />
        ))}
      </div>
        <ContentModules contentModules={contentModules} />
        <PageFooterLinks links={footerLinks} />
        
      </div>
      
      
    </div>
  );

  return (
    <PageWrapper
      pageTabs={renderFixedPageTabs()}
      hero={renderHero()}
      references={references}
    >
      {renderSeo()}
      {ctaSidebar ? columnTwo() : columnOne()}
    </PageWrapper>
  );
}

export default Page;

export const query = graphql`
  query page($contentful_id: String) {
    contentfulPage(contentful_id: {eq: $contentful_id}) {
      contentful_id
      title
      description {
        description
      }
      slug
      hero {
        bannerCopy
        image {
          file {
            url
          }
          description
          title
        }
        bynderBackgroundImage {
          name
          src
          original
        }
        slides {
          ... on Node {
            ... on ContentfulCtaBlock {
              title
              childContentfulCtaBlockBodyTextNode {
                body
              }
              ctaText
              ctaUrl
            }
            ... on ContentfulResourceCard {
              title
              body {
                body
              }
              linkText
              resourceFile {
                file {
                  url
                }
              }
              resourceUrl
              iFrame
            }
          }
        }
      }
      contentModules {
        ... on Node {
          ... on ContentfulPageChart {
            chartComponent
          }
          ... on ContentfulPageCopy {
            copy {
              copy
            }
          }
          ... on ContentfulPageTable {
            color
            tableBody {
              tableBody
            }
            tableHeaders {
              tableHeader
            }
            tableValues {
              tableValue
            }
            tableReferences {
              tableReferences
            }
          }
          ... on ContentfulAntiemeticGuidelines {
            aliasedTitle: title {
              title
            }
            steps {
              title
              body {
                body
              }
              columns {
                icon {
                  file {
                    url
                  }
                  description
                }
                body {
                  body
                }
              }
            }
          }
          ... on ContentfulProvenEffectivenessChart {
            colorScheme
            title
            subTitle
            block1Percentage
            block1Body {
              block1Body
            }
            block2Percentage
            block2Body {
              block2Body
            }
            block3Mg
            block3Header
            block3Body {
              block3Body
            }
            block3HighlightedText
            block3HighlightedTextSecondary
          }
          ... on ContentfulPageFooter {
            body {
              body
            }
          }
          ... on ContentfulPageCallout {
            body {
              body
            }
          }
          ... on ContentfulPageSelectWidget {
            adminTitle
            title
            colorScheme
            addBorder
            selectItems {
              title
              contentModules {
                ... on Node {
                  ... on ContentfulPageCopy {
                    copy {
                      copy
                    }
                  }
                  ... on ContentfulPageTable {
                    color
                    tableBody {
                      tableBody
                    }
                    tableHeaders {
                      tableHeader
                    }
                    tableValues {
                      tableValue
                    }
                    tableReferences {
                      tableReferences
                    }
                  }
                  ... on ContentfulPageRecommendedDosage {
                    title
                    eyebrow
                    body {
                      body
                    }
                    image {
                      fluid {
                        ...GatsbyContentfulFluid_tracedSVG
                      }
                      description
                    }
                    picture {
                      description
                      name
                      src
                      original
                    }
                    color
                    dosage
                    type
                    description {
                      description
                    }
                  }
                  ... on ContentfulProvenEffectivenessChart {
                    colorScheme
                    title
                    subTitle
                    block1Percentage
                    block1Body {
                      block1Body
                    }
                    block2Percentage
                    block2Body {
                      block2Body
                    }
                    block3Mg
                    block3Header
                    block3Body {
                      block3Body
                    }
                    block3HighlightedText
                    block3HighlightedTextSecondary
                  }
                  ... on ContentfulPageFooter {
                    body {
                      body
                    }
                  }
                  ... on ContentfulPageCallout {
                    body {
                      body
                    }
                  }
                  ... on ContentfulPagePonvIncidencePieCharts {
                    pie1References
                    pie2References
                    pie3References
                    pie4References
                  }
                }
              }
            }
          }
          ... on ContentfulPageLearnMoreLink {
            title
            url
          }
          ... on ContentfulPageContentCard {
            title
            linkText
            linkUrl
            body {
                body
              }
            bynderImage {
              name
              src
            }
          }
          ... on ContentfulPageLeadGenForm {
            formtitle
            copy {
              copy
            }
            ssAccount
            ssFormId
            ssFormDomain
          }
          ... on ContentfulPageLeadGenReturn {
            returntitle
            formDownloadUrl
          }
          ... on ContentfulPageGuidelineDrivenCare {
            guidelinesTitle
            childContentfulPageGuidelineDrivenCareGuidelinesBodyTextNode {
              guidelinesBody
            }
            childContentfulPageGuidelineDrivenCareGuidelinesFooterTextNode {
              guidelinesFooter
            }
            commonClassesTitle
            commonClassesList
            calloutPosition
            childContentfulPageGuidelineDrivenCareCalloutBodyTextNode {
              calloutBody
            }
          }
          ... on ContentfulPagePonvIncidencePieCharts {
            pie1References
            pie2References
            pie3References
            pie4References
          }
          ... on ContentfulPageQA {
            question
            answers
            contentModules {
              ... on Node {
                ... on ContentfulPageChart {
                  chartComponent
                }
                ... on ContentfulPageCopy {
                  copy {
                    copy
                  }
                }
                ... on ContentfulPageTable {
                  color
                  tableBody {
                    tableBody
                  }
                  tableHeaders {
                    tableHeader
                  }
                  tableValues {
                    tableValue
                  }
                  tableReferences {
                    tableReferences
                  }
                }
                ... on ContentfulPageRecommendedDosage {
                  title
                  eyebrow
                  body {
                    body
                  }
                  image {
                    fluid {
                      ...GatsbyContentfulFluid_tracedSVG
                    }
                    description
                  }
                  picture {
                    description
                    name
                    src
                    original
                  }
                  color
                  dosage
                  type
                  description {
                    description
                  }
                }
                ... on ContentfulProvenEffectivenessChart {
                  colorScheme
                  title
                  subTitle
                  block1Percentage
                  block1Body {
                    block1Body
                  }
                  block2Percentage
                  block2Body {
                    block2Body
                  }
                  block3Mg
                  block3Header
                  block3Body {
                    block3Body
                  }
                  block3HighlightedText
                  block3HighlightedTextSecondary
                }
                ... on ContentfulPageFooter {
                  body {
                    body
                  }
                }
                ... on ContentfulPageCallout {
                  body {
                    body
                  }
                }
                ... on ContentfulPagePonvIncidencePieCharts {
                  pie1References
                  pie2References
                  pie3References
                  pie4References
                }
              }
            }
          }
          ... on ContentfulPageAnchor {
            title
          }
        }
      }
      ctaSidebar {
        icon
        title
        childContentfulCtaBlockBodyTextNode {
          body
        }
        ctaText
        ctaUrl
      }
      footerLinks {
        title
        url
      }
      references {
        references
      }
    }
  }
`
