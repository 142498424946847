import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';
import { BtnPrimary } from '~components/buttons/buttons.component';
import styles from './page-card.module.scss';
import { Link } from 'gatsby';

const PageContentCard = ({title, body, bynderImage, linkText, linkUrl, image, secured=false}) => {
  const renderImg = () => {
    if (!image && !bynderImage) {
      return null;
    }

    if (bynderImage && bynderImage.length) {
      const {
        description,
        name,
        src,
      } = bynderImage[0];
      return (
        <img
          className={styles.cardImageResponsive}
          alt={description ? description : name}
          src={src}
        />
      );
    }

    if (secured) {
      const {
        description,
        file: { url },
      } = image.fields;

      return (
        <img
          className={styles.cardImageResponsive}
          alt={description}
          src={`${url}?fm=jpg&fl=progressive&w=550&fit=scale`}
        />
      );
    }

    const {
      description,
      fluid,
    } = image;

    return <Img alt={description} fluid={fluid} />;
  }
  const renderBody = () => {
    if (!body) {
      return null;
    }

    const text = secured ? body : body.body;

    return (
      <div className={styles.cardBody}>

        <RenderMarkdown text={text} />
        
      </div>
    );
  }
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <RenderMarkdown
          text={title}
          paragraph="h2"
        />
        
        {renderBody()}
        
        {linkUrl &&
          <BtnPrimary className={styles.cardButton} path={linkUrl}>
            {linkText}
          </BtnPrimary>
        }
      </div>
      <div className={`${styles.cardImage} hidden-mobile`}>
        {renderImg()}
      </div>
    </div>
  );
}

export default PageContentCard;