import React, {useEffect, useState} from 'react';
import DonutChart from './DonutChart';

const PatientDissatisfaction = () => {
  const defaultSize = 580;
  const [chartSize, setChartSize] = useState(defaultSize);

  useEffect(() => {
    // Holds the timer for setTimeout and clearInterval.
    let timer = null;
    const RESET_TIMEOUT = 100;

    const updateSize = () => {
      const newSize = window.innerWidth < 768 ? defaultSize / 2 : defaultSize;
      setChartSize(newSize);
    };

    window.addEventListener('resize', () => {
      clearInterval(timer);
      timer = setTimeout(updateSize, RESET_TIMEOUT);
    });
    updateSize();
  });

  const data = [
    {
      key: 'PONV',
      value: 49,
      color: 'purple',
    },
    {
      key: 'Pain',
      value: 27,
      color: 'greyDark',
    },
    {
      key: 'Alertness',
      value: 13,
      color: 'orange',
    },
    {
      key: 'Additional Cost',
      value: 11,
      color: 'teal',
    }
  ];

  return (
    <DonutChart
      width={chartSize}
      height={chartSize}
      data={data}
    />
  );
};

export default PatientDissatisfaction;
