import React from 'react';
import { strToClassName } from '~utils/strToClassName';
import PageFooterModule from '~components/page-footer-module/page-footer-module.component';
import PageCallout from '~components/page-callout/page-callout.component';
import PageSelect from '~components/page-select/page-select.component';
import RenderMarkdown from '~components/RenderMarkdown';
import AntiemeticGuidelines from '~components/charts/antiemetic-guidelines/antiemetic-guidelines.component';
import ProvenEffectiveness from '~components/charts/proven-effectiveness/proven-effectiveness.component';
import PONVIncidencePieCharts from '~components/charts/ponv-incidence-pie-charts/ponv-incidence-pie-charts.component';
import PatientDissatisfaction from '~components/charts/patient-dissatisfaction/patient-dissatisfaction.component';
import ResponseRatesTwoHours from '~components/charts/response-rates/response-rates-two-hours.component';
import ResponseRatesTwoHoursSurgeons from '~components/charts/response-rates/response-rates-two-hours-surgeons.component';
import ResponseRatesFourHours from '~components/charts/response-rates/response-rates-four-hours.component';
import ResponseRatesSixHours from '~components/charts/response-rates/response-rates-six-hours.component';
import ResponseRatesTabs from '~components/charts/response-rates/response-rates-tabs.component';
import GuidelineDrivenCare from '~components/charts/guideline-driven-care/guideline-driven-care.component';
import KaplanCurve from '~components/charts/kaplan-curve/kaplan-curve.component';
import BroadlyStudiedAntiemetic from '~components/charts/broadly-studied-antiemetic/broadly-studied-antiemetic.component';
import BroadlyStudiedAntiemeticPharmacy from '~components/charts/broadly-studied-antiemetic/broadly-studied-antiemetic-pharmacy.component';
import RecommendedDosage from '~components/charts/recommended-dosage/recommended-dosage.component';
import PageTable from '~components/tables/page-table/page-table.component';
import PageLearnMoreLink from '~components/page-learn-more-link/page-learn-more-link.component';
import PageQAndA from '~components/q-and-a/q-and-a.component';
import PonvVideo from '~components/ponv-video/ponv-video.component';
import PageContentCard from '~components/page-card/page-card.component';
import PageLeadGenForm from '~components/page-lead-gen-form/leadgenform.component';
import PageLeadGenReturn from '~components/page-lead-gen-return/leadgenreturn.component';
import ResourceCard from '~components/resource-card/resource-card.component';

const Components = {
  PONVIncidencePieCharts,
  PatientDissatisfaction,
  ResponseRatesTwoHours,
  ResponseRatesTwoHoursSurgeons,
  ResponseRatesFourHours,
  ResponseRatesSixHours,
  ResponseRatesTabs,
  BroadlyStudiedAntiemetic,
  BroadlyStudiedAntiemeticPharmacy,
  KaplanCurve,
  RecommendedDosage,
  PonvVideo,
  ResourceCard,
};

const ContentModules = ({contentModules, wrapperClass}) => {
  if (!contentModules || !Array.isArray(contentModules)) {
    return null;
  }

  const renderContentModules = () => {
    return contentModules.map((module, i) => {
      const typename = module.__typename
        ? module.__typename
        : module.sys
          ? module.sys.contentType.sys.id
          : false;

      if (!typename) {
        return null;
      }

      switch (typename) {
        // Chart.
        case "chart":
        case "ContentfulPageChart":
          const componentName = typename === 'chart' ? module.fields.chartComponent : module.chartComponent;
          const Chart = Components[componentName];
          if (!Chart) {
            return null;
          }
          return <Chart key={i} />;

        // Page Copy
        case "pageCopy":
        case "ContentfulPageCopy":
          const pageCopy = typename === 'pageCopy' ? module.fields.copy : module.copy.copy;
          return (
            <RenderMarkdown
              key={i}
              text={pageCopy}
            />
          );

        // Page Callout
        case "pageCallout":
        case "ContentfulPageCallout":
          const pageCallout = typename === 'pageCallout' ? module.fields.body : module.body.body;
          return (
            <PageCallout
              key={i}
              text={pageCallout}
            />
          );

        // Page select widget.
        case "pageSelectWidget":
          // The select widget from the Contentful API doesn't contain
          // all required data. This cannot be done until the Node.js
          // backend returns all required data.
          return null;

        case "ContentfulPageSelectWidget":
          const sWProps = typename === 'pageSelectWidget' ? module.fields : module;
          return (
            <PageSelect
              key={i}
              {...sWProps}
            />
          );

        case "pageFooter":
        case "ContentfulPageFooter":
          const pageFooter = typename === 'pageFooter' ? module.fields.body : module.body.body;
          return (
            <PageFooterModule
              key={i}
              text={pageFooter}
            />
          );

        // Antiemetic Guidelines.
        case "antiemeticGuidelines":
          // The Antiemetic Guidelines from the Contentful API doesn't contain
          // all required data. This cannot be done until the Node.js
          // backend returns all required data.
          return null;

        case "ContentfulAntiemeticGuidelines":
          return (
            <AntiemeticGuidelines
              key={i}
              title={module.aliasedTitle.title}
              steps={module.steps}
            />
          );

        // Proven Effectiveness.
        case "provenEffectivenessChart":
        case "ContentfulProvenEffectivenessChart":
          const pECProps = typename === 'provenEffectivenessChart' ? module.fields : module;
          return (
            <ProvenEffectiveness
              key={i}
              {...pECProps}
            />
          );

        case "ContentfulPageRecommendedDosage":
          return (
            <RecommendedDosage
              key={i}
              title={module.title}
              eyebrow={module.eyebrow}
              body={module.body.body}
              image={module.image}
              bynderImage={module.picture}
              color={module.color}
              value={module.dosage}
              type={module.type}
              description={module.description.description}
            />
          );

        // Page table.
        case "pageTable":
          // The table from the Contentful API doesn't contain
          // all required data. This cannot be done until the Node.js
          // backend returns all required data.
          return null;

        case "ContentfulPageTable":
          return (
            <PageTable
              key={i}
              body={module.tableBody}
              color={module.color}
              headers={module.tableHeaders}
              values={module.tableValues}
              references={module.tableReferences}
              wrapperClass={wrapperClass}
            />
          );

        case "pageLearnMoreLink":
        case "ContentfulPageLearnMoreLink":
          const lMLProps = typename === 'pageLearnMoreLink' ? module.fields : module;
          return (
            <PageLearnMoreLink
              key={i}
              {...lMLProps}
            />
          );

        // Page Content Card
        case "pageContentCard":
        case "ContentfulPageContentCard":
          const pCCProps = typename === 'pageContentCard' ? module.fields : module;
          return (
            <PageContentCard
              key={i}
              {...pCCProps}
            />
          );
        
        // Page Lead Gen Form
        case "pageLeadGenForm":
          case "ContentfulPageLeadGenForm":
            const pageLeadGenFormTitle = typename === 'pageLeadGenForm' ? module.fields.formtitle : module.formtitle;
            const pageLeadGenFormCopy = typename === 'pageLeadGenForm' ? module.fields.copy : module.copy.copy;
            const pLGFAccount = typename === 'pageLeadGenForm' ? module.fields.ssAccount : module.ssAccount;
            const pLGFId = typename === 'pageLeadGenForm' ? module.fields.ssFormId : module.ssFormId;
            const pLGFDomain = typename === 'pageLeadGenForm' ? module.fields.ssFormDomain : module.ssFormDomain;
            return (
              <PageLeadGenForm
                key={i}
                title={pageLeadGenFormTitle}
                text={pageLeadGenFormCopy}
                plgfaccount={pLGFAccount}
                plgfid={pLGFId}
                plgfdomain={pLGFDomain}
              />
            );
        
          // Page Lead Gen Return
          case "pageLeadGenReturn":
            case "ContentfulPageLeadGenReturn":
              const pageLeadGenReturnTitle = typename === 'pageLeadGenReturn' ? module.fields.returntitle : module.returntitle;
              const pLGRDownload = typename === 'pageLeadGenReturn' ? module.fields.formDownloadUrl : module.formDownloadUrl;
              return (
                <PageLeadGenReturn
                  key={i}
                  title={pageLeadGenReturnTitle}
                  plgrdownload={pLGRDownload}
                />
              );

        case "pageGuidelineDrivenCare":
        case "ContentfulPageGuidelineDrivenCare":
          const gDCProps = typename === 'pageGuidelineDrivenCare' ? module.fields : module;
          return (
            <GuidelineDrivenCare
              key={i}
              {...gDCProps}
            />
          );

        case "pagePonvIncidencePieCharts":
        case "ContentfulPagePonvIncidencePieCharts":
          const ponvIPCProps = typename === 'pagePonvIncidencePieCharts' ? module.fields : module;
          return (
            <PONVIncidencePieCharts
              key={i}
              {...ponvIPCProps}
            />
          );

        case "ContentfulPageQA":
          return (
            <PageQAndA
              key={i}
              {...module}
            />
          );
        
        case "ResourceCard":
        case 'ContentfulResourceCard':
          const resourceCardProps = typename === 'resourceCard' ? module.fields : module;
          //const resourceCard = secured ? module.fields : module;
          const secured = false;
          return (
            <ResourceCard
              key={i}
              {...resourceCardProps}
              secured={secured}
            />
          );

        case "pageAnchor":
        case "ContentfulPageAnchor":
          const pageAnchor = typename === 'pageAnchor' ? module.fields.title : module.title;

          return <div key={i} id={`page-anchor__${strToClassName(pageAnchor)}`}></div>;

        default:
          return null;

        

      }
    });
  }

  return (
    <>
      {renderContentModules()}
    </>
  );
}

export default ContentModules;
