import React from 'react';
import AnimatedPieHooks from './AnimatedPieHooks';
import styles from "./ponv-incidence-pie-charts.module.scss";

const PONVIncidencePieCharts = props => {
  const {
    pie1References,
    pie2References,
    pie3References,
    pie4References,
  } = props;

  const pieCharts = [
    {
      title: 'In high-risk patients',
      charts: [
        {
          data: [
            {
              value: .80,
              color: 'purple',
            },
            {
              value: .20,
              color: 'grey',
            },
          ],
          txtCenter: '~80%',
          txtCenterColor: 'purple',
          footnotes: `can experience <b>PONV</b><sup>${pie1References ? pie1References : '1,2'}</sup>`,
        },
        {
          data: [
            {
              value: .30,
              color: 'teal',
            },
            {
              value: .70,
              color: 'grey',
            },
          ],
          txtCenter: '>30%',
          txtCenterColor: 'teal',
          footnotes: `can experience <b>PONV despite prophylaxis</b><sup>${pie2References ? pie2References : '3,4'}</sup>`,
        },
      ]
    },
    {
      title: 'In patients who failed prophylaxis',
      charts: [
        {
          data: [
            {
              value: .95,
              color: 'tealLite',
            },
            {
              value: .10,
              color: 'grey',
            },
          ],
          txtCenter: '>95%',
          txtCenterColor: 'tealLite',
          footnotes: `can experience <b>nausea</b><sup>${pie3References ? pie3References : '4,5'}</sup>`,
        },
        {
          data: [
            {
              value: .20,
              color: 'purpleLite',
            },
            {
              value: .75,
              color: 'grey',
            },
          ],
          txtCenter: '>20%',
          txtCenterColor: 'purpleLite',
          footnotes: `can experience <b>vomiting</b><sup>${pie4References ? pie4References : '4'}</sup>`,
        },
      ]
    },
  ];

  const renderCharts = () => {
    return pieCharts.map((pieChart, i) => {
      return (
        <div
          key={i}
          className={styles.chartsGroup}
        >
          <div className={styles.chartsGroup__header}>{pieChart.title}</div>
          <div className={styles.chartsGroup__wrapper}>
            {pieChart.charts.map((chart, k) => {
              return (
                <div key={k} className={styles.chartsGroup__chart}>
                  <AnimatedPieHooks
                    data={chart.data}
                    width={140}
                    height={140}
                    innerRadius={70 - 9 * 2}
                    outerRadius={70}
                    txtCenter={chart.txtCenter}
                    txtCenterColor={chart.txtCenterColor}
                    txtCenterYAxis={10}
                    txtCenterClass={styles.chartsGroup__chartTxtCenter}
                  />

                  <div
                    className={styles.chartsGroup__chartFootnotes}
                    dangerouslySetInnerHTML={{
                      __html: chart.footnotes,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    })
  }

  return (
    <div className={styles.charts}>
      {renderCharts()}
    </div>
  );
}

export default PONVIncidencePieCharts;
