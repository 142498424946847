/**
 * A helper to generate a CSS ready class name.
 *
 * @param {string}
 *  A string to convert to a class name string.
 *
 * @returns {string}
 *   A CSS ready class name.
 */
const strToClassName = (string) => {
  return string
    .toLowerCase()
    .trim()
    .replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/g, '')
    .replace(/\s/g, '-');
}


export {
  strToClassName,
}
