import React, {useEffect, useState} from 'react';
import KaplanCurveChart from './KaplanCurveChart';

const KaplanCurve = () => {
  const [chartWidth, setChartWidth] = useState(745);
  useEffect(() => {
    const updateWidth = () => {
      const newWidth = window.innerWidth < 768 ? 425 : 745;
      setChartWidth(newWidth);
    }
    window.addEventListener('resize', () => updateWidth());
    updateWidth();
  })

  return (
    <div>
      <KaplanCurveChart
        width={chartWidth}
      />
    </div>
  );
};

export default KaplanCurve;
