import React from 'react';
import { BtnPrimary } from '~components/buttons/buttons.component';

import styles from './cta.module.scss';

const Cta = (props) => {
  const { icon, title, body, ctaUrl, ctaText } = props;
  const bodyStr = body ? body.body : '';
  return (
    <div className={styles.cta}>
    <span className={styles.icon}>
      <i className={`far fa-${icon}`}></i>
    </span>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.body}>{bodyStr}</div>
      <BtnPrimary className={styles.button} path={`/${ctaUrl}/`}>
        {ctaText}
      </BtnPrimary>
    </div>
  );
}

export default Cta;
