import React, { Component } from 'react';

class PonvVideo extends Component {
  componentDidMount() {
    this.renderVideo();
  }

  idWrapper = 'bynder-widgets-js-wrapper'
  idBynder = 'bynder-widgets-js'

  createScript() {
    const { idWrapper, idBynder } = this;
    const target = document.querySelector(`#${idWrapper}`);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = idBynder;
    script.setAttribute('data-account-url', 'bynder.acaciapharma.com');
    script.setAttribute('data-language', 'en');
    script.setAttribute('src', 'https://d8ejoa1fys2rk.cloudfront.net/bynder-embed/latest/bynder-embed.js');
    target.appendChild(script);
  }

  renderVideo() {
    this.createScript();
  }

  render() {
    const { idWrapper } = this;
    return (
      <div
        id={idWrapper}
        data-bynder-widget="video-item"
        data-media-id="556B970C-8293-41BA-AA46B4F09853B128"
        data-width=""
      />
    );
  }
}

export default PonvVideo;
