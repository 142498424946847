import React, { createRef, Component } from 'react';
import * as d3 from 'd3';

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.redrawChart();
  }

  redrawChart = () => {
    const { containerRef } = this;
    d3.select(containerRef.current).select('svg').remove();
    this.drawChart();
  }

  drawChart = () => {
    const { containerRef } = this;
    const { width, height, data, classGridTick, classBarLabel } = this.props;

    const marginWidth = 85;
    const marginHeight = 20;
    const widthWithMargin = width - marginWidth;
    const heightWithMargin = height - marginHeight;
    // Legend margin below chart.
    const legendMargin = 30 + 20 * data.length;

    const format = d3.format(".0%");

    const svg = d3.select(containerRef.current)
      .append('svg')
      .attr('height', height + legendMargin)
      .attr('width', width);

    const chart = svg.append('g')
      .attr('transform', `translate(${marginWidth}, ${marginHeight / 2})`);

    const xScale = d3.scaleBand()
      .range([0, widthWithMargin])
      .domain(data.map((s) => s.label))
      .padding(0.4);

    const yScale = d3.scaleLinear()
      .range([heightWithMargin, 0])
      .domain([0, 1]);

    const makeYLines = () => d3.axisLeft()
      .scale(yScale);

    const tickeVals = [0, .2, .4, .6, .8, 1];
    chart
      .append('g')
      .attr('class', 'grid')
      .call(makeYLines()
        .tickSize(-widthWithMargin, 0, 0)
        .tickFormat(format)
        .tickValues(tickeVals)
      )
      .selectAll("line,path")
      .style("stroke", "#b8b8b8")
      .style('opacity', 0.24);

    chart
      .selectAll('.tick text')
      .attr('class', classGridTick ? classGridTick : 'chart-bar__y-grid-tick');

    const barGroups = chart.selectAll()
      .data(data)
      .enter()
      .append('g')

    barGroups
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (g) => xScale(g.label))
      .attr('y', (g) => yScale(g.value))
      .attr('height', (g) => heightWithMargin - yScale(g.value))
      .attr('width', xScale.bandwidth())
      .attr('fill', g => g.color);

    barGroups
      .append('text')
      .attr('class', classBarLabel ? classBarLabel : 'chart-bar__label')
      .attr('x', (a) => xScale(a.label) + xScale.bandwidth() / 2)
      .attr('y', (a) => yScale(a.value) - 30)
      .attr('text-anchor', 'middle')
      .attr('fill', a => a.color)
      .text((a) => `${a.value * 100}%`);

    svg
      .append('text')
      .attr('class', 'label')
      .attr('x', -(heightWithMargin / 2) - marginHeight)
      .attr('y', 17)
      .attr('transform', 'rotate(-90)')
      .attr('text-anchor', 'middle')
      .text('Patients');

    const labelsWrapper = svg
      .append('g')
      .attr('transform', 'translate(126, 366)');

    const legend = labelsWrapper
      .append("g");
    legend
      .selectAll("g")
      .data(data.reverse())
      .join("g")
      .attr("transform", (d, i) => `translate(-40,${(i - (data.length - 1) / 2) * 20})`)
      .call(g => g.append("rect")
        .attr("width", 14)
        .attr("height", 14)
        .attr("fill", d => d.color))
      .call(g => g.append("text")
        .attr("x", 24)
        .attr("y", 9)
        .attr("dy", "0.2rem")
        .attr('font-size', '14px')
        .text(d => d.label));
  }

  render() {
    const { containerRef } = this;
    return <div ref={containerRef} />;
  }
}

export default BarChart;
