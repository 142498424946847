import React, { useEffect } from 'react';
import styles from './leadgenreturn.module.scss';

const LeadGenReturn = ({title, plgrdownload}) => {
  useEffect(() => {
    const renderReturn = () => {
      window.open(plgrdownload, '_self');
    }
    setTimeout(renderReturn, 8000);
  },[plgrdownload])
  
  return (
    <div className={styles.pageLeadGenReturn}></div>
  );
}

export default LeadGenReturn;