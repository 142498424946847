import React, { Component } from 'react';
import ContentModules from '~components/content-modules/content-modules.component';
import { strToClassName } from '~utils/strToClassName';
import { v4 as uuid } from 'uuid';

import styles from './page-select.module.scss';

class PageSelect extends Component {
  state = {
    activeOption: null,
  }

  componentDidMount() {
    this.setState({
      activeOption: this.getOptionVal(this.props.selectItems[0].title),
    });
  }

  getOptionVal = text => {
    return text.toLowerCase().replace(/\s/g, '_').trim();
  }

  renderTitle = () => {
    const { title } = this.props;
    if (!title) {
      return null;
    }
    return <h2 className={styles.pageSelect__header}>{title}</h2>;
  }

  onSelectChange = e => {
    if (e.target.value === this.state.activeOption) {
      return;
    }
    this.setState({
      activeOption: e.target.value,
    });
  }

  renderSelectItems = () => {
    const { getOptionVal, onSelectChange } = this;
    const { adminTitle, title, selectItems } = this.props;
    const { activeOption } = this.state;
    const idSelect = `${title ? strToClassName(title) : strToClassName(adminTitle)}__${uuid()}`;
    return (
      <div className={styles.pageSelect__inputWrapper}>
        <select
          name={idSelect}
          className={styles.pageSelect__input}
          id={idSelect}
          onChange={onSelectChange}
          onBlur={onSelectChange}
          value={activeOption ? activeOption : getOptionVal(selectItems[0].title)}
        >
          {selectItems.map((item, i) => {
            return (
              <option
                key={i}
                value={getOptionVal(item.title)}
              >
                {item.title}
              </option>
            );
          })}
        </select>
        <i className="fa fa-chevron-down" aria-hidden="true" />
      </div>
    );
  }

  renderItem = () => {
    const { getOptionVal } = this;
    const { selectItems, colorScheme, addBorder } = this.props;
    const { activeOption } = this.state;
    const selectedItem = selectItems.find(item => getOptionVal(item.title) === activeOption);
    if (!selectedItem) {
      return null;
    }

    const { contentModules } = selectedItem;

    let classes = [
      styles.pageSelect__content,
    ];

    if (addBorder) {
      classes.push(styles.pageSelect__contentBorder);
    }

    return (
      <div className={classes.join(' ')}>
        <ContentModules
          contentModules={contentModules}
          wrapperClass={colorScheme === 'blue' ? styles.pageSelect__contentNegTop : null}
        />
      </div>
    )
  }

  render() {
    const { renderTitle, renderSelectItems, renderItem } = this;
    const { colorScheme } = this.props;
    let classes = [
      styles.pageSelect,
    ];

    if (colorScheme === 'blue') {
      classes.push(styles.pageSelectBlue);
    } else if (colorScheme === 'purple') {
      classes.push(styles.pageSelectPurple);
    }

    return (
      <div className={classes.join(' ')}>
        {renderTitle()}
        {renderSelectItems()}
        {renderItem()}
      </div>
    );
  }
}

export default PageSelect;
