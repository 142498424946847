import React, {useEffect, useRef, useState} from 'react';
import BarChart from './BarChart';
import styles from "./response-rates.module.scss";

const ResponseRatesTwoHours = () => {
  const [chartWidth, setChartWidth] = useState(367);
  const refChartContainer = useRef();

  const paddingChartRight = 53;


  useEffect(() => {
    // Holds the timer for setTimeout and clearInterval.
    let timer = null;
    const RESET_TIMEOUT = 100;

    const updateWidth = () => {
      if (refChartContainer.current) {
        const newWidth = window.innerWidth < 768 ? refChartContainer.current.offsetWidth : refChartContainer.current.offsetWidth / 2 - paddingChartRight;
        setChartWidth(newWidth);
      }
    }

    window.addEventListener('resize', () => {
      clearInterval(timer);
      timer = setTimeout(updateWidth, RESET_TIMEOUT);
    });
    updateWidth();
  })



  const data = [
    {
      label: 'Placebo (n=235)',
      value: .49,
      color: '#727272'
    },
    {
      label: 'Barhemsys 10 mg (n=230)',
      value: .70,
      color: '#ea860f'
    },
  ];

  return (
    <>
      <div ref={refChartContainer} className={styles.responseRates__chart}>
        <div className={styles.responseRates__barChart}>
          <BarChart
            width={chartWidth}
            height={327}
            data={data}
            classGridTick={styles.responseRates__barChartYGridTick}
            classBarLabel={styles.responseRates__barChartLabel}
          />
        </div>
        <div className={styles.responseRates__card}>
          <h3 className={styles.responseRates__header2}>Secondary endpoint</h3>
          <h2 className={styles.responseRates__header1}>In patients who failed prophylaxis</h2>
          <p className={styles.responseRates__cardCopy}><span className={styles.responseRates__highlight}>70%</span> (160/230) of patients met the criteria for complete response at 2 hours after receiving a 10 mg dose of Barhemsys compared with <span className={styles.responseRates__highlightAlt}>49%</span> (116/235) of placebo-treated patients<sup>2,§</sup></p>
          <p className={styles.responseRates__cardFooter}>At each of the prespecified interim time points (4 and 6 hours), the percentage of patients who met the criteria for complete response was approximately 20% higher with Barhemsys 10 mg compared with placebo.<sup>2</sup></p>
        </div>
      </div>
      <div className={styles.responseRates__footnote}>
        <sup>§</sup>The secondary endpoint was not adequately powered nor error controlled, and observed treatment differences cannot be regarded as statistically significant.
      </div>
    </>
  );
}

export default ResponseRatesTwoHours;
