import React from 'react';
import {Link} from 'gatsby';
import RenderMarkdown from '~components/RenderMarkdown';

const LearnMoreLink = ({title, url}) => {
  return (
    <p>
      <Link to={`/${url}`}>
        <RenderMarkdown
          text={title}
          paragraph="span"
        />
      </Link>
    </p>
  );
}

export default LearnMoreLink;
