import React, { Component, createRef } from 'react';
import * as d3 from 'd3';

import styles from './donut-chart.module.scss';

class DonutChart extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.redrawChart();
  }

  redrawChart = () => {
    const { containerRef } = this;
    d3.select(containerRef.current).select('svg').remove();
    this.drawChart();
  }

  drawChart = () => {
    const { containerRef } = this;
    const { width, height, data } = this.props;
    const radius = Math.min(width, height) / 2 - 40;
    const legendRadius = width / 72.5;
    const legendPosition = legendRadius * -2.25;

    // D3 functions.
    const arc = d3.arc()
      .innerRadius(radius * 0.62)
      .outerRadius(radius * 0.8);
    const outerArc = d3.arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);
    const pie = d3.pie()
      .sort((a, b) => d3.ascending(a.value, b.value))
      .value(d => d.value);

    // Input data.
    const pieData = pie(data);
    const colorScale = d3.scaleOrdinal()
      .domain([
        'purple',
        'greyDark',
        'orange',
        'teal',
      ])
      .range([
        '#4d0348',
        '#727272',
        '#e9850e',
        '#005d81',
      ]);

    // Draw chart container.
    const svg = d3.select(containerRef.current)
      .append('svg')
        .attr('width', width)
        .attr('height', height)
      .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Draw chart slices.
    svg.selectAll('allSlices')
      .data(pieData)
      .enter()
      .append('path')
        .attr('d', arc)
        .attr('fill', d => colorScale(d.data.color));

    // Draw chart values.
    svg.selectAll('allValues')
      .data(pieData)
      .enter()
      .append('text')
        .text(d => `${d.data.value}%`)
        .attr('transform', d => {
          var pos = outerArc.centroid(d);
          return `translate(${pos})`;
        })
        .attr('fill', d => colorScale(d.data.key))
        .attr('text-anchor', d => {
          return (d.endAngle + d.startAngle) / 2 > Math.PI ? 'end' : 'start';
        })
        .attr('class', styles.value);

    // Draw and position chart legend.
    const legend = svg.append('g').attr('class', styles.legend);
    legend.selectAll('bullets')
      .data(pieData)
      .enter()
      .append('circle')
        .attr('cx', legendPosition)
        .attr('cy', (d, i) => `${i*1.3 - 0.3}em`)
        .attr('r', legendRadius)
        .attr('fill', d => colorScale(d.data.color))

    legend.selectAll('labels')
      .data(pieData)
      .enter()
      .append('text')
        .text(d => d.data.key)
        .attr('dy', (d, i) => `${i*1.3}em`);

    // Center legend box.
    let bb = legend.node().getBBox();
    let centerX = bb.width / -2 - bb.x;
    let centerY = bb.height / -2 - bb.y;
    legend.attr('transform', `translate(${centerX}, ${centerY})`);
  }

  render() {
    return <div className="donut-chart" ref={this.containerRef} />;
  }
};

export default DonutChart;
