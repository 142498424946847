import React from 'react';
import Img from 'gatsby-image';

import styles from './recommended-dosage.module.scss';

const RecommendedDosage = props => {
  const {
    title,
    eyebrow,
    body,
    image,
    bynderImage,
    color,
    value,
    type,
    description
  } = props;

  const renderImg = () => {
    if (bynderImage && bynderImage.length) {
      const {
        description,
        name,
        original,
      } = bynderImage[0];
      return (
        <img
          className={styles.imgResponsive}
          alt={description ? description : name}
          src={original}
        />
      );
    }
    const {
      description,
      fluid,
    } = image;

    return <Img alt={description} fluid={fluid} />;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.eyebrow}>{eyebrow}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>{body}</div>
      <div className={styles.details}>
        <div className={styles.image}>{renderImg()}</div>
        <div className={styles.dosage}>
          <div className={[styles.value, color].join(' ')}>{value}</div>
          <div className={styles.type}>{type}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    </div>
  )
};

export default RecommendedDosage;
