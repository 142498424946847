import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-callout.module.scss';

const PageCallout = ({text}) => {
  return (
    <div className={styles.pageCallout}>
      <RenderMarkdown
        text={text}
      />
    </div>
  );
}

export default PageCallout;
