import React from 'react';
import styles from "./bsa-template.module.scss";

const BSATemplate = ({ header, copy, dosageData, useAlt }) => {
  const renderDosage = () => {
    return dosageData.map((d, i) => {
      return (
        <div
          key={i}
          className={`${styles.bsa__graphsCol}${useAlt ? ` ${styles.bsa__graphsColAlt}` : ''}`}
        >
          <div className={`${styles.bsa__graphsDosage}${useAlt ? ` ${styles.bsa__graphsDosageAlt}` : ''}`}>{d.dosage}</div>
          <h3 className={styles.bsa__header}>{d.header}</h3>
          <p dangerouslySetInnerHTML={{__html: d.html}} />
        </div>
      );
    });
  }
  return (
    <article className={styles.bsa}>
      <h2>{header}</h2>
      <p className={`${styles.bsa__copy}${useAlt ? ` ${styles.bsa__copyAlt}` : ''}`} dangerouslySetInnerHTML={{__html: copy}} />
      <div className={styles.bsa__graphs}>
        {renderDosage()}
      </div>
    </article>
  );
}

export default BSATemplate;
