import React, { Component, createRef } from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './proven-effectiveness.module.scss';

class ProvenEffectiveness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useAltColor: props.colorScheme === 'Blue' ? true : false,
    }
    this.refBigBlockBody = createRef();
  }

  componentDidMount() {
    this.handleHighlightingText();
  }

  highlightText = (className, strings) => {
    const { refBigBlockBody } = this;
    if (!className && !Array.isArray(strings) && strings.length) {
      return;
    }
    strings.forEach(k => {
      refBigBlockBody.current.innerHTML = refBigBlockBody.current.innerHTML.replace(k, `<span class="${className}">${k}</span>`)
    });
  }

  handleHighlightingText = () => {
    const { highlightText } = this;
    const { useAltColor } = this.state;
    const {
      block3HighlightedText,
      block3HighlightedTextSecondary,
    } = this.props;
    let highlight1Classes = [
      styles.peChart__hight1,
    ];
    if (useAltColor) {
      highlight1Classes.push(styles.peChart__hight1Alt);
    }

    highlightText(highlight1Classes.join(' '), block3HighlightedText.split(',').map(item => item.trim()));
    highlightText(styles.peChart__hight2, block3HighlightedTextSecondary.split(',').map(item => item.trim()));
  }

  renderSmallBlock = ({percentage, body, isColored = false}) => {
    const { useAltColor } = this.state;
    let wrapperClasses = [
      styles.peChart__smallBlock,
    ];
    if (isColored) {
      wrapperClasses.push(styles.peChart__smallBlockHighlight);

      if (useAltColor) {
        wrapperClasses.push(styles.peChart__smallBlockHighlightAlt);
      }
    }
    return (
      <div className={wrapperClasses.join(' ')}>
        <div className={styles.peChart__smallBlockInner}>
          <h4 className={styles.peChart__smallBlockHeader}>{percentage}</h4>
          <RenderMarkdown text={body} />
        </div>
      </div>
    );
  }

  renderBigBlock = ({mg, header, body}) => {
    const { useAltColor } = this.state;
    const { refBigBlockBody } = this;
    let mgClasses = [
      styles.peChart__bigBlockMg,
    ];
    if (useAltColor) {
      mgClasses.push(styles.peChart__bigBlockMgAlt);
    }
    return (
      <div className={styles.peChart__bigBlock}>
        <div className={mgClasses.join(' ')}>{mg} mg</div>
        <h4 className={styles.peChart__bigBlockHeader}>{header}</h4>
        <div
          ref={refBigBlockBody}
          className={styles.peChart__bigBlockBody}
        >
          <RenderMarkdown
            text={body}
          />
        </div>
      </div>
    );
  }

  render() {
    const { renderSmallBlock, renderBigBlock } = this;
    const {
      title,
      subTitle,
      block1Percentage,
      block1Body,
      block2Percentage,
      block2Body,
      block3Header,
      block3Mg,
      block3Body,
    } = this.props;

    return (
      <div className={styles.peChart}>
        <h2 className={styles.peChart__title}>{title}</h2>
        <h3 className={styles.peChart__subTitle}>{subTitle}</h3>
        <div className={styles.peChart__smallBlocks}>
          {renderSmallBlock({
            percentage: block1Percentage,
            body: block1Body.block1Body ? block1Body.block1Body : block1Body,
          })}
          {renderSmallBlock({
            percentage: block2Percentage,
            body: block2Body.block2Body ? block2Body.block2Body : block2Body,
            isColored: true,
          })}
        </div>
        {renderBigBlock({
          mg: block3Mg,
          header: block3Header,
          body: block3Body.block3Body ? block3Body.block3Body : block3Body,
        })}
      </div>
    );
  }
}

export default ProvenEffectiveness;
