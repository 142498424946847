import React, { Component, createRef } from 'react';
import InputRange from 'react-input-range';
import * as d3 from 'd3';
import 'react-input-range/lib/css/index.css';
import './kaplan-curve.scss';

class KaplanCurveChart extends Component {

  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.state = { value: 24 };
    this.data = this.getData();
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.redrawChart();
  }

  redrawChart = () => {
    const { containerRef } = this;
    d3.select(containerRef.current).select('svg').remove();
    this.drawChart();
  }

  getData() {
    return {
      series: [
        {
          color: "#ff8000",
          values: [
            1, 0.769565217, 0.695652174, 0.639130435, 0.591304348, 0.556521739, 0.526086957, 0.504347826, 0.491304348, 0.491304348, 0.486956522, 0.469565217, 0.465217391, 0.465217391, 0.460869565, 0.456521739, 0.447826087, 0.439130435, 0.430434783, 0.430434783, 0.430434783, 0.426086957, 0.42173913, 0.417391304, 0.417391304,
          ],
          results: [
            230, 177, 160, 147, 136, 128, 121, 116, 113, 113, 112, 108, 107, 107, 106, 105, 103, 101, 99, 99, 99, 98, 97, 96, 96,
          ],
        },
        {
          color: "#5f5f5f",
          values: [
            1, 0.621276596, 0.493617021, 0.425531915, 0.370212766, 0.340425532, 0.327659574, 0.323404255, 0.319148936, 0.319148936, 0.314893617, 0.314893617, 0.310638298, 0.306382979, 0.30212766, 0.30212766, 0.30212766, 0.30212766, 0.29787234, 0.289361702, 0.289361702, 0.285106383, 0.285106383, 0.285106383, 0.285106383,
          ],
          results: [
            235, 146, 116, 100, 87, 80, 77, 76, 75, 75, 74, 74, 73, 72, 71, 71, 71, 71, 70, 68, 68, 67, 67, 67, 67,
          ],
        }
      ],
      hours: [],
    };
  }

  arrayUnique(array) {
    const a = array.concat();
    for(let i = 0; i < a.length; ++i) {
      for(let j = i + 1; j < a.length; ++j) {
        a[i] === a[j] && a.splice(j--, 1);
      }
    }
    return a;
  }

  drawChart = () => {
    const { containerRef } = this;
    const width = this.props.width;
    const height = 525;
    const margin = { top: 5, right: 10, bottom: 145, left: 120 };
    // Selected hours.
    const hoursNumber = +this.state.value;
    // Narrow ticks number if mobile screen enabled.
    const ticksNumber = width < 700 ? 6 : hoursNumber;

    // Get chart data.
    this.data = this.getData();

    // Narrow down the chart data results when hours selected.
    for (const series of this.data.series) {
      series.values = series.values.slice(0, hoursNumber + 1);
      series.results = series.results.slice(0, hoursNumber + 1);
    }
    for (let i = 0; i <= hoursNumber; i++) {
      this.data.hours.push(i);
    }

    const svg = d3.select(containerRef.current)
      .append('svg')
      .attr("viewBox", [0, 0, width, height])
      .style("overflow", "visible");

    // X Scale.
    const xScale = d3.scaleLinear()
      .domain(d3.extent(this.data.hours))
      .range([margin.left, width - margin.right]);
    const xTicks = this.arrayUnique(xScale.ticks(ticksNumber).concat(xScale.domain().pop()));
    const axisBottom = d3.axisBottom(xScale)
      .ticks(ticksNumber)
      .tickSize(13)
      .tickPadding(5)
      .tickValues(xTicks);
    svg.append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(axisBottom)
      .attr('font-size', '12px');

    // Y Scale
    const yScale = d3.scaleLinear()
      .domain([0, 1])
      .range([height - margin.bottom, margin.top]);
    const axisLeft = d3.axisLeft(yScale)
      .ticks(6)
      .tickSize(13);
    svg.append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(axisLeft)
      .attr('font-size', '18px');

    // Time after Study table block.
    svg.append("text")
      .text("Barhemsys 10 mg")
      .attr('font-size', '10px')
      .attr("x", 2)
      .attr("y", height - 39)
      .clone()
      .text("Placebo")
      .attr('font-size', '10px')
      .attr("x", 2)
      .attr("y", height - 9)
      .clone()
      .text("Number at risk")
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .attr("x", 2)
      .attr("y", height - 60)
      .clone()
      .text("Time After Study Drug Administration (Hours)")
      .attr('font-size', '14px')
      .attr('font-weight', 'bold')
      .attr("x", width / 2 - margin.left)
      .attr("y", height - 85);

    const tableRowBarhemsys = d3.axisBottom(xScale)
      .ticks(ticksNumber)
      .tickValues(xTicks)
      .tickSize(13)
      .tickPadding(85);
    svg.append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(tableRowBarhemsys.tickFormat((i) => this.data.series[0].results[i]))
      .attr('font-size', '12px');

    const tableRowPlacebo = Object.assign({}, tableRowBarhemsys);
    tableRowPlacebo.tickPadding(115);
    svg.append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(tableRowPlacebo.tickFormat((i) => this.data.series[1].results[i]))
      .attr('font-size', '12px');

    // Draw legend block.
    svg.append("line")
      .attr("fill", "none")
      .attr("stroke-width", 2)
      .attr("stroke", "#ff8000")
      .attr("x1", width - 210)
      .attr("x2", (width - 210) + 13)
      .attr("y1", 7)
      .attr("y2", 7);

    svg.append("text")
      .text("Barhemsys 10 mg (n=230)")
      .attr('font-size', '14px')
      .attr("x", width - 185)
      .attr("y", 12);

    svg.append("line")
      .attr("fill", "none")
      .attr("stroke-width", 2)
      .attr("stroke", "#5f5f5f")
      .attr("x1", width - 210)
      .attr("x2", (width - 210) + 13)
      .attr("y1", 37)
      .attr("y2", 37);

    svg.append("text")
      .text("Placebo (n=235)")
      .attr('font-size', '14px')
      .attr("x", width - 185)
      .attr("y", 42)
      .clone()
      .text("HR (95% CI): 0.63 (0.50, 0.80)")
      .attr("y", 72);

    // Set axis styles.
    svg.selectAll('g.tick')
      .select('line')
      .attr('stroke', "#e8e9ea");
    svg.selectAll('g.tick')
      .select('text')
      .attr('font-family', '"Roboto", Arial, Helvetica, sans-serif')
      .attr('font-weight', '500');
    svg.selectAll('path.domain').attr('stroke', "#e8e9ea");

    // Vertical title text.
    svg.append('text')
      .attr('class', 'label')
      .attr('font-size', '16px')
      .attr('transform', 'rotate(-90)')
      .attr('text-anchor', 'middle')
      .attr('x', -190)
      .attr('y', 20)
      .text('Probability of Patient Continuing to Meet')
      .clone()
      .text('Criteria for Complete Response')
      .attr('x', -190)
      .attr('y', 45);

    // Draw chart lines.
    const line = d3.line()
      .defined(d => !isNaN(d))
      .x((d, i) => xScale(this.data.hours[i]))
      .y(d => yScale(d));

    svg.append("g")
      .attr("fill", "none")
      .attr("stroke-width", 2)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .selectAll("path")
      .data(this.data.series)
      .join("path")
      .style("mix-blend-mode", "multiply")
      .attr("stroke", d => d.color)
      .attr("d", d => line(d.values));
  }

  render() {
    return (
      <div>
        <span>Try adjusting the slider to take a closer look at the time points.</span>
        <div className="input-range__wrapper">
          <div className="input-range__title">Hours</div>
          <InputRange
            maxValue={24}
            minValue={6}
            value={this.state.value}
            onChange={value => this.setState({ value })} />
        </div>
        <div ref={this.containerRef} />
        <br/><br/>
      </div>
    );
  }
}

export default KaplanCurveChart;
