import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './guideline-driven-care.module.scss';

const GuidelineDrivenCare = props => {
  const {
    guidelinesTitle,
    childContentfulPageGuidelineDrivenCareGuidelinesBodyTextNode,
    guidelinesBody,
    childContentfulPageGuidelineDrivenCareGuidelinesFooterTextNode,
    guidelinesFooter,
    commonClassesTitle,
    commonClassesList,
    calloutPosition,
    childContentfulPageGuidelineDrivenCareCalloutBodyTextNode,
    calloutBody,
  } = props;

  const renderCommonClassesList = () => {
    return (
      <ol className={styles.guidelines__list}>
        {commonClassesList.map((item, i) => {
          return (
            <li key={i}>
              <RenderMarkdown
                text={item}
                paragraph="span"
              />
            </li>
          );
        })}
      </ol>
    );
  }

  const renderCallout = () => {
    const txtCalloutBody = childContentfulPageGuidelineDrivenCareCalloutBodyTextNode
      ? childContentfulPageGuidelineDrivenCareCalloutBodyTextNode.calloutBody
      : calloutBody
        ? calloutBody
        : null;

    if (!txtCalloutBody) {
      return null;
    }

    let classes = [
      styles.guidelines__rowAlt,
    ];

    if (calloutPosition === 'top') {
      classes.push(styles.guidelines__rowAltTop)
    }

    return (
      <div className={classes.join(' ')}>
        <RenderMarkdown
          text={txtCalloutBody}
        />
      </div>
    );
  }

  const renderGuidelinesBody = () => {
    const txtGuidelinesBody = childContentfulPageGuidelineDrivenCareGuidelinesBodyTextNode
      ? childContentfulPageGuidelineDrivenCareGuidelinesBodyTextNode.guidelinesBody
      : guidelinesBody
        ? guidelinesBody
        : null;

    if (!txtGuidelinesBody) {
      return null;
    }

    return (
      <RenderMarkdown
        text={txtGuidelinesBody}
      />
    );
  }

  const renderGuidelinesFooter = () => {
    const txtGuidelinesFooter = childContentfulPageGuidelineDrivenCareGuidelinesFooterTextNode
      ? childContentfulPageGuidelineDrivenCareGuidelinesFooterTextNode.guidelinesFooter
      : guidelinesFooter
        ? guidelinesFooter
        : null;

    if (!txtGuidelinesFooter) {
      return null;
    }

    return (
      <div className={styles.guidelines__footnotes}>
        <RenderMarkdown
          text={txtGuidelinesFooter}
        />
      </div>
    );
  }

  return (
    <div className={styles.guidelines}>
      {calloutPosition === 'top' ? renderCallout() : null}
      <div className={styles.guidelines__row}>
        <div className={styles.guidelines__col}>
          <h2 className={styles.guidelines__header}>
            <RenderMarkdown
              text={guidelinesTitle}
              paragraph="span"
            />
          </h2>
          {renderGuidelinesBody()}
          {renderGuidelinesFooter()}
        </div>
        <div className={styles.guidelines__col}>
          <h2 className={styles.guidelines__header}>
            <RenderMarkdown
              text={commonClassesTitle}
              paragraph="span"
            />
          </h2>
          {renderCommonClassesList()}
        </div>
      </div>
      {calloutPosition === 'bottom' ? renderCallout() : null}
    </div>
  );
}

export default GuidelineDrivenCare;
