import React from 'react';
import Tabs from '~components/tabs/tabs.component';
import ResponseRatesTwoHours from './response-rates-two-hours.component';
import ResponseRatesFourHours from './response-rates-four-hours.component';
import ResponseRatesSixHours from './response-rates-six-hours.component';

const ResponseRatesTabs = () => {
  return (
    <Tabs>
      <div label="2 Hours<sup>§</sup>">
        <ResponseRatesTwoHours />
      </div>
      <div label="4 Hours<sup>§</sup>">
        <ResponseRatesFourHours />
      </div>
      <div label="6 Hours<sup>§</sup>">
        <ResponseRatesSixHours />
      </div>
    </Tabs>
  );
}

export default ResponseRatesTabs;
